import "core-js/modules/es.object.to-string.js";
/** confirm.js */
import { MessageBox } from 'element-ui';
var message = {
  //这里无法使用element的操作

  confirm: function confirm(content) {
    var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "提示";
    var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "warning";
    return new Promise(function (resolve, reject) {
      // 异步处理
      // 处理结束后、调用resolve 或 reject
      MessageBox.confirm(content, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: type
      }).then(function () {
        return resolve(true);
      }).catch(function () {
        return resolve(false);
      });
    });
  },
  alert: function alert(content) {
    var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "提示";
    return new Promise(function (resolve, reject) {
      MessageBox.alert(content, title, {
        confirmButtonText: '确定',
        callback: function callback(action) {
          return resolve(true);
        }
      });
    });
  }
};
export default message;