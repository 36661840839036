import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//系统设置相关的路由
var router = [{
  path: '/system_message',
  name: 'system_message',
  component: function component() {
    return import('@/pages/system/message/index');
  },
  meta: {
    auth: true,
    cache: true,
    title: '系统配置'
  }
}, {
  //客服列表
  path: '/system/custom_service/index',
  name: 'system_custom_service_index',
  component: function component() {
    return import('@/pages/system/custom_service/index');
  },
  meta: {
    auth: true,
    cache: true,
    title: '座席管理'
  }
}, {
  //学校客服
  path: '/system/custom_service/school',
  name: 'system_custom_service_school',
  component: function component() {
    return import('@/pages/system/custom_service/school');
  },
  meta: {
    auth: true,
    cache: true,
    title: '学校客服'
  }
}, {
  path: '/business_management',
  name: 'business_management',
  component: function component() {
    return import('@/pages/business_management/index');
  },
  meta: {
    auth: true,
    cache: true,
    title: '支付账号'
  }
}, {
  path: '/business_management/associated',
  name: 'business_management_associated',
  component: function component() {
    return import('@/pages/business_management/associated');
  },
  meta: {
    auth: true,
    cache: true,
    title: '支付关联配置'
  }
}, {
  path: '/business_management/add',
  name: 'business_management_add',
  component: function component() {
    return import('@/pages/business_management/add');
  },
  meta: {
    auth: true,
    cache: true,
    title: '添加支付账号'
  }
}, {
  path: '/business_management/edit',
  name: 'business_management_edit',
  component: function component() {
    return import('@/pages/business_management/edit');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑支付账号'
  }
}];
export default router;