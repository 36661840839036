var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "pt15 fs20 web_title"
  }, [_vm._v("管理后台")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };