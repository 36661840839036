function getHeader() {
  var columns = [{
    label: '序号',
    prop: 'number'
  }, {
    label: '提交时间',
    prop: 'submit_time'
  }, {
    label: '所用时间',
    prop: "use_time"
  }, {
    label: '来源',
    prop: "source"
  }, {
    label: '来源详情',
    prop: "source_detail"
  }, {
    label: '来自IP',
    prop: "ip"
  }, {
    label: '面试日期：',
    prop: "meet_day"
  }, {
    label: '您的姓名：',
    prop: "user_name"
  }, {
    label: '您的性别：',
    prop: "sex"
  }, {
    label: '婚姻状况',
    prop: "marry"
  }, {
    label: '请输入您的手机号码：',
    prop: "phone"
  }, {
    label: '您的身份证号码：',
    prop: "idcard"
  }, {
    label: '招聘渠道',
    prop: "platform_name"
  }, {
    label: '近14天内有无湖北旅居史，有无与湖北旅居人员或与本次疫情确诊、疑似、密切接触者存在密切接触？',
    prop: "is_hubei"
  }, {
    label: '是否为外地来渝人员？',
    prop: "is_outside"
  }, {
    label: '近期是否有发热、咳嗽、呼吸困难、乏力、胸闷、腹泻症状？',
    prop: "is_hot"
  }, {
    label: '是否为孕妇或有无慢性基础性疾病？',
    prop: "is_illness"
  }, {
    label: '现住地小区/楼栋是否有已通报的确诊、疑似或密切接触人员',
    prop: "is_community"
  }, {
    label: "根据自己的手机号运营商选择相应二维码，长按识别并截图保存查询结果，返回上传查询截图",
    prop: "qrcode_img"
  }, {
    label: '本人实名手机号通过运营商（移动、电信、联通）查询近期轨迹信息有无异常？',
    prop: "is_phone"
  }, {
    label: '您本人已认真阅读上述内容并确认填报信息完全真实？',
    prop: "is_real"
  }, {
    label: '紧急联络人姓名：',
    prop: "pressing_name"
  }, {
    label: '紧急联络人手机号码：',
    prop: "pressing_phone"
  }, {
    label: '户籍关系',
    prop: "home_condition"
  }, {
    label: '是否有以下相关工作经历',
    prop: "word_history"
  }, {
    label: '文化程度',
    prop: "education"
  }, {
    label: '毕业学校名称：',
    prop: "school"
  }, {
    label: '入学日期：',
    prop: "in_school"
  }, {
    label: '毕业日期：',
    prop: "out_school"
  }];
  return columns;
}
export default {
  getHeader: getHeader
};