import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.keys.js";
export default {
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (instance) {
      return instance.$router.replace(JSON.parse(from.params.route));
    });
  },
  render: function render(h) {
    return h();
  }
};