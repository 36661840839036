import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//课程相关的路由
var router = [{
  path: '/course/resources',
  name: 'course_resources',
  component: function component() {
    return import('@/pages/course/resources');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑课程资源'
  }
}, {
  path: '/course_0',
  name: 'course_0',
  component: function component() {
    return import('@/pages/course/index_0');
  },
  meta: {
    auth: true,
    cache: true,
    title: '特长兴趣班管理'
  }
}, {
  path: '/course_add_0',
  name: 'course_add_0',
  component: function component() {
    return import('@/pages/course/add_0');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增特长兴趣班'
  }
}, {
  path: '/course_edit_0/:id',
  name: 'course_edit_0',
  component: function component() {
    return import('@/pages/course/edit_0');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑特长兴趣班'
  }
}, {
  path: '/course_1',
  name: 'course_1',
  component: function component() {
    return import('@/pages/course/index_1');
  },
  meta: {
    auth: true,
    cache: true,
    title: '330/430课程管理'
  }
}, {
  path: '/course_add_1',
  name: 'course_add_1',
  component: function component() {
    return import('@/pages/course/add_1');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增330/430课程'
  }
}, {
  path: '/course_edit_1/:id',
  name: 'course_edit_1',
  component: function component() {
    return import('@/pages/course/edit_1');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑330/430课程'
  }
}, {
  path: '/course_2',
  name: 'course_2',
  component: function component() {
    return import('@/pages/course/index_2');
  },
  meta: {
    auth: true,
    cache: true,
    title: '830课程管理'
  }
}, {
  path: '/course_add_2',
  name: 'course_add_2',
  component: function component() {
    return import('@/pages/course/add_2');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增830课程'
  }
}, {
  path: '/course_edit_2/:id',
  name: 'course_edit_2',
  component: function component() {
    return import('@/pages/course/edit_2');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑830课程'
  }
},
//寒暑假课程
{
  path: '/course_3',
  name: 'course_3',
  component: function component() {
    return import('@/pages/course/index_3');
  },
  meta: {
    auth: true,
    cache: true,
    title: '假期课程'
  }
}, {
  path: '/course_add_3',
  name: 'course_add_3',
  component: function component() {
    return import('@/pages/course/add_3');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增假期课程'
  }
}, {
  path: '/course_edit_3/:id',
  name: 'course_edit_3',
  component: function component() {
    return import('@/pages/course/edit_3');
  },
  meta: {
    auth: true,
    cache: true,
    title: '编辑假期课程'
  }
}];
export default router;