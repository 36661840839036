import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import md5 from 'md5';
var api_sign_secret = md5("api_sign_secret");
export default function (data) {
  if (!data.hasOwnProperty("timestamp") && data.timestamp !== "") {
    data.timestamp = parseInt(new Date().getTime() / 1000);
  }
  var sortData = {};
  Object.keys(data).sort().forEach(function (key) {
    sortData[key] = data[key];
  });
  sortData.sign = md5(JSON.stringify(sortData) + api_sign_secret);
  return sortData;
}
;