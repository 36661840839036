import _objectSpread from "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import Mock from 'mockjs';
import qs from 'qs';
import withCredentials from './patch/withCredentials';

/* 补丁 */
withCredentials(Mock);

/* Mock 默认配置 */
Mock.setup({
  timeout: '200-300'
});

/* 扩展 [生成器] */
var Generator = function Generator(prop, template) {
  var obj = {};
  obj[prop] = [template];
  return Mock.mock(obj);
};

/* 扩展 [循环] */
var Repeat = function Repeat(num, itemTemplate) {
  return Generator("data|".concat(num), itemTemplate).data;
};
var CustomExtends = {
  Generator: Generator,
  Repeat: Repeat,
  Mock: Mock,
  Random: Mock.Random
};
var extend = function extend(prop, value) {
  CustomExtends[prop] = value;
};

/* 装配配置组 */
var wired = function wired(_ref) {
  var url = _ref.url,
    type = _ref.type,
    body = _ref.body;
  return _objectSpread({
    method: type,
    params: qs.parse(url.split('?').length > 1 ? url.split('?')[1] : ''),
    body: JSON.parse(body),
    url: qs.parse(url.split('?')[0])
  }, CustomExtends);
};
var setup = function setup(path, method, handle) {
  Mock.mock(RegExp(path), method, typeof handle === 'function' ? function (o) {
    return handle(wired(o));
  } : handle);
};
var load = function load(collection) {
  collection.map(function (_ref2) {
    var path = _ref2.path,
      method = _ref2.method,
      handle = _ref2.handle;
    if (method === '*') {
      method = ['get', 'head', 'post', 'put', 'delete', 'connect', 'options', 'trace', 'patch'];
    }
    if (typeof method === 'string' && method.indexOf('|') > -1) method = method.split('|');
    if (method instanceof Array) {
      method.map(function (item) {
        return setup(path, item, handle);
      });
    } else {
      setup(path, method, handle);
    }
  });
};
export default {
  setup: setup,
  load: load,
  extend: extend
};