import "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/core-js/modules/es.array.iterator.js";
import "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/core-js/modules/es.promise.js";
import "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/core-js/modules/es.object.assign.js";
import "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/*
 * @Author: your name
 * @Date: 2021-06-15 11:24:46
 * @LastEditTime: 2021-09-22 16:49:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \admin\src\main.js
 */
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
// 核心插件
import d2Admin from '@/plugin/d2admin';
// store
import store from '@/store/index';

// 菜单和路由设置
import router from './router';
import menuHeader from '@/menu/header';
import menuAside from '@/menu/aside';
import { frameInRoutes } from '@/router/routes';

//vue-axios 配置
import VueAxios from 'vue-axios';
import axios from '@/plugin/axios/';
import layer from 'vue-layer';
import 'vue-layer/lib/vue-layer.css';
import awaitToJs from 'await-to-js';
import util from '@/libs/util';
Vue.use(VueAxios, axios);
Vue.prototype.$to = awaitToJs;
Vue.prototype.helper = import('@/libs/helper');
Vue.prototype.$layer = layer(Vue); //引入layer
Vue.prototype.$util = util;
// 核心插件
Vue.use(d2Admin);

//表格导入
import pluginImport from '@d2-projects/vue-table-import';
Vue.use(pluginImport);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    this.$store.commit('d2admin/menu/headerSet', menuHeader);
    // 设置侧边栏菜单
    this.$store.commit('d2admin/menu/asideSet', menuAside);
    // 初始化菜单搜索功能
    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow');
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load');
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get');
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');