var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "s-canvas"
  }, [_c("canvas", {
    attrs: {
      id: "s-canvas",
      width: _vm.contentWidth,
      height: _vm.contentHeight
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };