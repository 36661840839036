import _objectSpread from "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
var userDB = [{
  username: 'admin',
  password: 'admin',
  uuid: 'admin-uuid',
  name: 'Admin'
}, {
  username: 'editor',
  password: 'editor',
  uuid: 'editor-uuid',
  name: 'Editor'
}, {
  username: 'user1',
  password: 'user1',
  uuid: 'user1-uuid',
  name: 'User1'
}];
export default [{
  path: '/api/login',
  method: 'post',
  handle: function handle(_ref) {
    var body = _ref.body;
    var user = userDB.find(function (e) {
      return e.username === body.username && e.password === body.password;
    });
    if (user) {
      return {
        code: 0,
        msg: '登录成功',
        data: _objectSpread(_objectSpread({}, user), {}, {
          token: '8dfhassad0asdjwoeiruty'
        })
      };
    } else {
      return {
        code: 401,
        msg: '用户名或密码错误',
        data: {}
      };
    }
  }
}];