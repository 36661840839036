import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//系统设置相关的路由
var router = [{
  path: '/course_order_7',
  name: 'course_order_7',
  component: function component() {
    return import('@/pages/order/course_7');
  },
  meta: {
    auth: true,
    cache: true,
    title: '假期订单'
  }
}, {
  path: '/course_order/:type',
  name: 'course_order',
  component: function component() {
    return import('@/pages/order/course');
  },
  meta: {
    auth: true,
    cache: true,
    title: '课程订单'
  }
}, {
  path: '/service_order/:type',
  name: 'service_order',
  component: function component() {
    return import('@/pages/order/service');
  },
  meta: {
    auth: true,
    cache: true,
    title: '托管订单'
  }
}, {
  path: '/order/add',
  name: 'order_add',
  component: function component() {
    return import('@/pages/order/add');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增订单'
  }
}, {
  path: '/order/details',
  name: 'order_details',
  component: function component() {
    return import('@/pages/order/details');
  },
  meta: {
    auth: true,
    cache: true,
    title: '订单详情'
  }
}, {
  path: '/order/flow',
  name: 'order_flow',
  component: function component() {
    return import('@/pages/order/flow');
  },
  meta: {
    auth: true,
    cache: true,
    title: '流水日志'
  }
}, {
  path: '/order/add_flow',
  name: 'order_add_flow',
  component: function component() {
    return import('@/pages/order/add_flow');
  },
  meta: {
    auth: true,
    cache: true,
    title: '新增流水'
  }
}, {
  path: '/order/log',
  name: 'order_log',
  component: function component() {
    return import('@/pages/order/log');
  },
  meta: {
    auth: true,
    cache: true,
    title: '操作日志'
  }
}, {
  path: '/order_basic',
  name: 'order_basic',
  component: function component() {
    return import('@/pages/order_basic/index');
  },
  meta: {
    auth: true,
    cache: true,
    title: '订单初始数据'
  }
}, {
  path: '/order_refund_mult',
  name: 'order_refund_mult',
  component: function component() {
    return import('@/pages/order/refund_mult');
  },
  meta: {
    auth: true,
    cache: true,
    title: '订单批量退费'
  }
}];
export default router;