var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", {
    staticClass: "body-container"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main"
  }, [_c("div", {
    staticClass: "top_div"
  }, [_c("div", {
    staticClass: "line"
  }, [_vm._v(_vm._s(_vm.time_text) + "好，欢迎使用" + _vm._s(_vm.system_name) + "！")]), _c("div", {
    staticClass: "line"
  }, [_vm._v("您上次的登录时间："), _vm.admin ? _c("span", [_vm._v(_vm._s(_vm.admin.last_login_time))]) : _vm._e()])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };