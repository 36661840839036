import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", _vm._b({
    attrs: {
      data: _vm.list
    }
  }, "el-table", _vm.table, false), [_c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      width: "160"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "预览",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c("div", {
          staticClass: "theme-preview",
          style: {
            backgroundImage: "url(".concat(_vm.$baseUrl).concat(scope.row.preview, ")")
          }
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "address",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.activeName === scope.row.name ? _c("el-button", {
          attrs: {
            type: "success",
            icon: "el-icon-check",
            round: ""
          }
        }, [_vm._v(" 已激活 ")]) : _c("el-button", {
          attrs: {
            round: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleSelectTheme(scope.row.name);
            }
          }
        }, [_vm._v(" 使用 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };