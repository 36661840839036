import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import d2Mock from './d2-mock';
var req = function req(context) {
  return context.keys().map(context);
};
var options = req(require.context('./api/', true, /\.js$/)).filter(function (e) {
  return e.default;
}).map(function (e) {
  return e.default;
});
options.forEach(function (option) {
  d2Mock.load(option);
});