import util from '@/libs/util.js';

// 菜单 侧边栏
function getMenu() {
  var menu = [{
    path: '/index',
    title: '首页',
    icon: 'home'
  }];
  return menu;
}
export default getMenu();