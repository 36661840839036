var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-page-cover"
  }, [_c("div", {
    staticClass: "d2-page-cover__logo"
  }, [_vm._t("default")], 2), _c("p", {
    staticClass: "d2-page-cover__title"
  }, [_vm._v("系统管理后台 " + _vm._s(_vm.$version))]), _c("p", {
    staticClass: "d2-page-cover__sub-title"
  }, [_vm._v("可视化数据管理")]), _c("p", {
    staticClass: "d2-page-cover__build-time"
  }, [_vm._v("FINAL BUILD TIME " + _vm._s(_vm.$buildTime))]), _vm._t("footer")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };